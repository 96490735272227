.partners-page {
    /*max-width: 800px;*/
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .partner-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .partner-card {
    max-width: 300px;
    min-width: 300px;
    margin: 20px;
    padding: 20px;
    text-align: center;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .partner-card img {
    max-width: 200px;
    margin-bottom: 20px;
    border-radius: 50%;
    border: white 5px solid;
  }
  
  .partner-card h3 {
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: large;
    
  }
  
  .partner-card p {
    margin-bottom: 20px;
    font-style: oblique;
    font-size: small;
  }
  
  .partner-card a {
    display: inline-block;
    padding: 10px 20px;
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s;
    transition: 0.2s;
    border: white 1px solid;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: stretch;
  }
  
  .partner-card a:hover {
    padding: 10px 30px;
    transition: 0.2s;
  }
  .partner-heading {
    text-align: center;
    text-transform: uppercase;
    -webkit-text-stroke: white 1px;
    color: transparent;
    letter-spacing: 3px;
  }