@import url('https://fonts.cdnfonts.com/css/minecraft-4');
:root {
  --brown :  rgb(68, 44, 24);
  --not-quite-black: #23272a;
}
@font-face {
  font-family: Monument Extended;
  src: url("/src/styles/MonumentExtended-Ultrabold.otf") format("opentype");
  font-weight: 1000;
}
.addgames{
  background-color: rgba(0, 0, 0, 0.342);
  padding: 30px;
}
.input-search-nav {
  border: white 1px solid !important;
  margin: 5px !important;
  background-color: #000000b7 !important;
  backdrop-filter: blur(30px) !important;
  transition: 1.2s;
}
.input-search-nav:hover {
  transition: 1.2s;
}
.input-search {
  margin-left: 30px;
  width: 350px !important;
}
body {
  color-scheme: dark;
  overflow-x: hidden;
  /*background-image: url("../../frontend/public/assets/images/4.png");*/
  background-attachment: fixed;
  background-size: cover;
  background-color: var(--not-quite-black);
  font-family: 'Monument Extended', sans-serif;
  color: white;
  margin-top: 60px;
  transition: ease-in-out 0.2s all; 
  background: linear-gradient(112.1deg, rgb(11 11 11) 11.4%, var(--not-quite-black) 70.2%);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.navlink {
  float: left;
  width: 100%;
  max-width: 15vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  font-size: 20px;
  border-bottom: solid;
border-bottom-width: 1px;
}
.navbar {
  backdrop-filter: blur(30px);
  background: #0000007a;
  text-align: center !important;
    color: white;
    width: 100vw;
    height: 50px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999999999999999 !important;
    transition: ease-in-out 0.2s all;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: solid white 2px;

}
.icon-nav{
  width: 20px;
  height: 20px;
  filter: invert(100%);
  transition: .3s;
  padding: 6px;
  cursor: pointer;
}
.icon-nav:hover {
  filter: invert(100%);
  transition: .3s;
  background-color: rgba(255, 255, 255, 0.185);
  border-radius: 30%;
}




.icon-body {
  width: 80px;
  height: 80px;


}
.navlink:hover {
background-color: rgb(255, 255, 255);
color: black;
}


/* unvisited link */
a:link {
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: 0.2s;

}

/* visited link */
a:visited {
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: 0.2s;

}

/* mouse over link */
a:hover {
  color: rgb(68, 68, 68);
  text-decoration: none;
  transition: 0.2s;
}

/* selected link */
a:active {
  color: rgb(153, 153, 153);
  text-decoration: none;
  transition: 0.2s;

}
a {
  transition: 0.2s;

}
.login-button{
  display: block;
  height: 50px;
  width: 25vw;
  margin-left: 25vw;
  background-color: rgba(117, 117, 117, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  color:rgb(255, 255, 255);
  border: white;
  text-align: left;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.308);
  transition: 0.2s;
  cursor: pointer;
}
.login-button:hover {
  box-shadow: 14px 14px 0px rgba(0, 0, 0, 0.308);
  transition: 0.2s;
  scale:1.06;

}
::placeholder {
  color:white;
}
.logo-nav{
width: 40px;
margin: 5px;
}
.login-log-out{
  font-family: "Monument Extended";
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
  margin-left: 1vw;
  position: absolute;
  width: 2vw;
  bottom: 30px;
  z-index: 999;
  border: none;
  left: 0px;
  background-color: var(--brown);
  color: white;
}
.game-content-text{
  color: black;
}
.login-log-out:hover{
  background-color: rgb(255, 255, 255);
  color: var(--brown) !important;
background-size: cover;
transition: 0.2s;

}
/* unvisited link */
.navlink a:link {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: 0.2s;

}

/* visited link */
.navlink a:visited {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: 0.2s;

}

/* mouse over link */
.navlink a:hover {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: 0.2s;

}

/* selected link */
.navlink a:active {
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: 0.2s;

}
.navlink a {
  transition: 0.2s;
  border-top: solid;

}

.article-conent {
  padding: 20px;
  text-align: left;
}
.article-conent-list {
  width: 30vw;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  flex-direction:row;
  background-color: #0e0e0e91;
  border-radius: 30px;
  padding: 30px;
  margin: 20px;
  backdrop-filter: blur(20px);
  text-align: center;
}
.h2-game-bar {
  text-transform: uppercase;
  letter-spacing: 2px;
}
.commenttext {
  text-align: left;
  padding-left: 2vw;

}
.commentdiv {
  text-align: left;
  padding-left: 2vw;
}
.article-content-text{
  width: calc(100% - 50px);
  padding: 50px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.573);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.backbutton {
  width: 30px;
  height: 30px;
  filter: invert(100%);
  cursor: pointer;
  transition: 0.2s;
  position: absolute;
  top: 30px;
  left: 30px;
}
.backbutton:hover {
transition: 0.2s;
scale: 1.1;
}
.article-content-text h1{
  text-transform: uppercase;
}
.input-name {
  margin-bottom: 20px;
}
.input-comment{
margin-bottom: 50px;
}
textarea {
  resize: none;
}
.nav-right{
  float: right;
}
.upvotes-button .login-log-out {
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 4px;
  padding: 4px;
}
.gamediv {
  float: left;
  margin-right: -5px;
}


.game-container{
  align-content: center;
  align-items: center;
  display: grid;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  left: 60px;
  overflow-x: hidden;
  top: 30px;
  justify-items: center;
}



.live-div {
  position: relative;
  text-align: center;
  margin-top: 300px;
  padding-bottom: 30px;
}
.image-container {
   /*background-image: url("../../frontend/public/assets/images/1.png");*/
  background-size: cover;
  position: relative; /* Needed to position the cutout text in the middle of the image */
  height: auto; /* Some height */
  width: 40vw;
  background-attachment:fixed;
}


.footer{
  position: absolute;
  bottom:0px;
  padding: 0px 100px;
  left: 0px;
  width: 100%;
  height: 100px;
  background-color: rgb(0, 0, 0);
z-index: 9999999999999999;
margin-top: 200px;
}
.playgamesbutton{
  background-color: var(--brown);
  padding: 10px 10px;
  max-width: 220px;
  text-align: center;
  border-radius: 100px;
  cursor: pointer;
  font-size: 15px;
  color: rgb(255, 255, 255);
  border: white 1px solid;
  transition: 0.2s;
  padding:12px 100px;
  letter-spacing: 3px;
}
.playgamesbutton:hover{
background-color: white;
scale: 1.2 1.0;
transition: 0.2s;
color: var(--brown);

}

.text {
  color: none;
  font-size: 96px; /* Responsive font size */
  font-weight: bold;
 /* margin: 0 auto;  Center the text container */
  width: 400px; /* Set the width of the text container */
  text-align: left; /* Center text */
  mix-blend-mode: screen; /* This makes the cutout text possible */
 /* margin: 0 200px;*/
}

.hero-div {
  -webkit-text-stroke: #fff 0.5px;
  /* margin-left: 110px; */
  max-width: 50vw;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 10vw auto;
}
.hero-h1 {
  font-size: 56px;
  margin: 0px;
  margin-left: 50px;
  margin-bottom: 50px;
  text-align: left;

}
.hero-p1 {
     font-size: 20px;
    text-align: left;
    color: #4b4b4b;
   /* margin: 17px 200px;*/
    cursor: crosshair;
    width: auto;
    -webkit-text-stroke: rgba(61, 61, 61, 0) 0.5px;
    display: block;
}
.hero-p1-1{
  font-size: 20px;
    text-align: left;
    color: #4b4b4b;
   margin: 17px 200px;
    cursor: crosshair;
    width: auto;
    -webkit-text-stroke: rgba(61, 61, 61, 0) 0.5px;
    display: block;
}

.backwards-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: .2s;
  filter: invert(100%);
}


.search-results-div {
  position: relative;
  z-index: 66;
  text-transform: uppercase;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px;
  height: 275px;
  margin: 40px 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.623);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50%;
  letter-spacing: 2px;
  font-size: 40px;
}
.search-results-div-contents {
  z-index: 9999999999999999;
  float: left;
  background-color: var(--not-quite-black);
  padding: 10px;
  min-width: 725px;
  min-height: 250px;
}
.trending-search-card {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.search-results-div-blur {
  filter: blur(50px);
    position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  text-transform: uppercase;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  width: 750px !important;
  height: 300px!important;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-size: cover;

}
.center {
  text-align: center;
  color: transparent;
  font-size: 40px;
  -webkit-text-stroke: rgb(255, 255, 255) 1px;
  margin-left: calc(50vw - 301px);
}
.center404 {
  text-align: center;
  color: transparent;
  font-size: 40px;
  text-transform: uppercase;
color: white;}
.center404links {
  text-align: center;
  color: transparent;
  font-size: 2rem;
  text-transform: uppercase;
  -webkit-text-stroke: rgb(255, 255, 255) 1px;
  transition: 0.5s;

}
.center404links:hover {
  scale: 1.1;
  transition: 0.5s;
}

.searchimg{
  padding: 4px;
  margin-left: 5px;
  height: 34px;
  width: 34px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10%;
  z-index: 500;
  transition: 0.3s;
}
.searchimg-nav {
  padding: 1px 1px;
  height: 30px;
  width: 30px;
  z-index: 500;
  filter: invert(0)!important;

  left: 5px;
  z-index: 9999999999999999;
}
.searchimg-nav:hover{
  transition: 0.2s;
  background-color: rgba(0, 0, 0, 0.8);


}
.searchimg:hover{
  scale: 1.1; 
  transition: 0.2s;
  border-radius: 10%;

}
form input[type="text"] {
  width: 300px;
  font-size: 17px;
  float: left;
  border: none;
  background: rgba(0, 0, 0, 0.8);
border-radius: 3px;
  height: 40px;
  transition: 0.2s;
  font-family: 'Monument Extended';
}
form input[type="text"]:hover {
transition: 0.2s;
}
/*form {
  margin-bottom: 50px;
}*/

.submitsearch {
  font-family: 'Monument Extended';
  font-size: 20px;
  cursor: pointer;
  transition: .2s;
  border: none;
  height: 30px;
  background-color: transparent;
}
.submitsearch:hover {
  transition: .2s;
  align-items: center;
  -webkit-text-stroke: white 0.5px;
  color: rgba(0, 0, 0, 0);
}

.h3search {
  background-color: var(--not-quite-black);
  border-radius: 3px;
  padding: 10px;
  margin: 10px 10px 0;
  width: 30px;
}
.search-results-div-img{
  height: 150px;
  width: 150px; 
  border-radius: 10px;
  margin: 10px 10px;
position: absolute;
top: 30px;
right: 30px;
}
.fullscreen {
  height: 25px;
  width: 25px;}

.fullscreen:hover {
  scale: 1.1;
  transition: .2s;
  filter: invert(100%);
  align-items: center;
}
.backwards-button:hover {
  scale: 1.1;
  transition: .2s;
}
.game-bar {
  height: 30px;
  border-top-left-radius: 10px;
  /*background-color: var(--not-quite-black);*/
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.game-opt {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0);
  z-index:1;
}
.arrow {
  height: 30px;
  filter: invert(100%);
  cursor: pointer;
  transition: .2s;
  rotate: 90deg;
  right:0px;
  
}
.iframe-game {
  width: calc(90vw);
  height: 90vh;
  background-color: white;
  float: left;
  /*position: absolute;*/
  left: 60px;
  top: 40px;
  border-radius: 0px ;
  border: none;
}

.iframe-block-external {
  width: calc(90vw);
  height: 90vh;
  float: left;
  left: 60px;
  top: 40px;
  border-radius: 6px ;
  border: none;
  display: flex;
  flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-around;
    flex-wrap: wrap;
    background-size: cover;
    backdrop-filter: blur(20px);
    background-position: center center;
  }

p4 {
  font-size: 15px;
}
.sidebar-gamepage{
  width: calc(16vw - 70px);
  border-radius: 6px;
  height: 90vh;
  /*background: rgba(0, 0, 0, 0.647);*/
  position: absolute;
  right: 10px;
  top: 60px;
  text-align: center;
}
.sidebar-gamepage h1 {
  font-size: 20px;
  text-align: center;
  color: white;
}

.sidebar-gamepage-img {
  width: 80%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: 0.2s;
  /*border: 2px solid white;*/
}
.sidebar-gamepage-img:hover {
  scale: 1.15;
  transition: 0.2s;
}
.button-nav-user {
background-color: transparent;
max-width: 50px;
position: absolute;
bottom: 5px;
left: 3px;
text-align: center;
border: none;
padding: none;
cursor: pointer;
}

.icon-comment {
  width: 20px;
  filter: invert(100%);
}
/*----------------------------------------------------------------------*/
.games {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: calc(1020px + 100px);
  margin: 0px calc((100vw - 1020px - 100px)/2);
}

.game {
  width: 300px;
  margin: 20px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;
  background-color: #101010;
  transition: 0.2s;

}
.game:hover {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  scale: 1.05;
  transition: 0.2s;
}
.game img {
  width: 100%;
  object-fit: cover;
  max-width: 300px;
  max-height: 300px;
}

.game h2 {
  margin: 10px;
  text-align: center;

}
/*1020px*/
.game p {
  margin: 10px;
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gameh1 {
  text-align: center;
}
.game a {
  display: block;
  margin: 10px;
  padding: 10px;
  background-color: var(--not-quite-black);
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

.game a:hover {
  background-color: var(--brown);

}
/*------------------------------FOOTER------------------------------------*/





footer.dark-mode {
  background-color: #0e0e0e91;
  color: #fff;
  width: 100vw;
      display: flex;
      margin-top: 200px;
      margin-left: -10px;

}
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  
  margin-bottom: 10px;

}

.footer-logo {
  height: 150px;
}

.footer-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
    flex-wrap: wrap;
  width: 100%;
}


.footer-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  transition: 0.2s;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.footer-links a:hover {
  transition: 0.2s;
  color: #0e0e0e;
  -webkit-text-stroke: #fff 0.3px;
}
.footerlinks{
  margin-bottom: 10px;
  font-size: 13px;
}
.footer-social ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

footer.dark-mode h3 {
-webkit-text-stroke: 0.5px #fff;
  color: transparent;
  letter-spacing: 1px;
  font-size: 20px;
  letter-spacing: 3px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.footer-social a {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  transition: color 0.3s ease-in-out;
}

.footer-social a:hover {
  color: var(--brown);
}
footer.dark-mode .span4 {
  /*width: calc((100vw - 850px)/4);*/
  text-align: left;
  border-left: rgba(233, 233, 233, 0.541) 2px solid;
  padding: 7px;

}

.login-input{
  display: block;
  height: 50px;
  width: 50vw;
  margin-left: 25vw;
  background-color: rgba(117, 117, 117, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  color:white;
  border: white;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.308);
  transition: 0.2s;
  margin-top: 10px;
}
.login-input{
  box-shadow: 14px 14px 0px rgba(0, 0, 0, 0.308);
  transition: 0.2s;
  scale:1.06;

}
.loading-screen {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  background-color: #000000ac;
  font-size: 24px;
  font-weight: bold;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  z-index: 9999999999999999999999999999;
  -webkit-text-stroke: white 1px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: transparent;
}


.progress-bar {
  width: 40%;
  height: 30px;
  background-color: #ddd;
}

.progress {
  height: 100%;
  background-color: var(--brown);
  width: 0;
  transition: width 0.6s ease-in-out;
  padding: 0px;
}
.hidden {
  display: none;
}
.progressbar-text {
  font-size: 15px;
  margin-left: 10px;
}


.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in.active {
  opacity: 1;
}
.search-description {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.752);
  border-radius: 3px;
  /*padding: 5px;*/
  letter-spacing: initial;
  font-style: italic;
  text-transform:initial;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  float: left;
  margin-left: 30px;
  margin-top: 10px;
  width: 500px;
}

.Carousel {
width: 400px;
height: 400px;
  transition: 0.2s;
  
}
.filter-games-container {
  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
  transition: 0.3s ease-in-out;
 

}
.searchContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  transition: 0.3s ease-in-out;
  margin-left: 30px;
  margin-bottom: 20px;

}
.filter-games-header {
  display: flex;
    align-items: center;
    padding: 0 10px 0 10px;
    /* border: 1px solid #ccc; */
    cursor: pointer;
    /* width: 350px; */
    height: 42px;
    transition: 0.3s ease-in-out;
    margin-left: 5px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 2px;

}

.filter-games-header:hover {
color: white;
transition: 0.3s ease-in-out;

}

.filter-icon {
  margin-right: 0.5rem;
  color: white !important;
}

.filter-games-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 5px;
  background-color: #000;
  border: 1px solid #ccc;
  transition: 0.2s;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 99;

}

.filter-games-dropdown a {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  color: #c6c6c6;
  border-bottom: 1px solid #ccc;
  transition: 0.3s ease-in-out;

}

.filter-games-dropdown a:last-child {
  border-bottom: none;
}

.filter-games-dropdown a:hover {
  background-color: var(--not-quite-black);
  color: white;
  letter-spacing: 3px;
  transition: 0.3s ease-in-out;

}


@media screen and (max-width: 1200px) {
  
  .Carousel{
    width: 100%;
    height: 400px;
  }
}
 

.filter-icon-game {
  background-color: blue;
  height: 25px;
  width: 35px;
  border-radius: 7px;
  border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;

  position: absolute;
  top: 0px;
  left: 0px;
}
.sideAd-gamespage {
  position: fixed;
  right: 30px;
  top: 20px;
  height: 95vh;
  background-color: var(--not-quite-black);
  width: 200px;
  text-align: center;
  align-items: center;
  padding: 10px 0 0;
  transition: ease-in-out 0.2s all; 

}


.arrow-nav {
  rotate: 90deg;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 30px;
  background-color: white;
  border-radius: 50%;
  bottom: 105px;
  padding: 5px;
  cursor: pointer;
  transition: ease-in-out 0.2s all; 
}

.arrow-nav-clicked {
  rotate: -90deg;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 130px;
  background-color: white;
  border-radius: 50%;
  bottom: 10px;
  padding: 5px;
  cursor: pointer;
  transition: ease-in-out 0.2s all; 

}

.nav-extended {
  
    /*rgb(26, 26, 26)*/
    background: var(--not-quite-black);
    color: white;
    width: 150px;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index:99999999999999999999999;
    transition: ease-in-out 0.2s all; 
    text-align: left !important;
}

.icon-nav-extended{
  width: 20px;
  height: 20px;
  filter: invert(100%);
  padding-right: 10px;
    transition: .3s;
  padding: 6px;
  float: left;
  transition: ease-in-out 0.2s all; 
  margin-top: 2px;
}
.icon-nav-extended:hover {
  /*
  filter: invert(0%);
 
  background-color: rgb(255, 255, 255);
  border-radius: 30%;
  transition: ease-in-out 0.2s all; 
*/
 transition: .3s;
}

.h1-nav {
  font-size: 15px;
  text-transform: uppercase;
  float: left;
  padding-left: 10px;
  margin-top: 10px;

}
.hidden {
  display: none;
}



.submitsearch-nav-extended {
  font-family: 'Monument Extended';
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  transition: .2s;
  border: none;
  height: 40px;
  border-radius: 10%;
  background-color: transparent;
  float: left;
}
.submitsearch-nav-extended:hover {
  transition: .2s;
  }


  .searchimg-nav-extended {
    padding: 1px 1px;
    height: 30px;
    width: 30px;
    z-index: 500;
    filter: invert(0)!important;
    left: 5px;
    z-index: 9999999999999999;
    float: left;

  }

  .logo-nav-extended{
    width: 50px;
    height: auto;
    margin-top: 10px;
    transition: .3s;
    padding: 0 0 0 10%;
    transition: ease-in-out 0.2s all; 
    display: flex;
    align-items: center;
    flex-direction:column;
    

  }

  .nav-div {
    display: flex;
    flex-direction:column;
    justify-content:   left;
    height: 35px;
    transition: ease-in-out 0.2s all; 
    flex-direction: initial;
    float: none;
    width: 50px;

  }

  
  #loading {
    display: flex;
    position: fixed;
    z-index: 1e+94;;
    width: 100%;
    height: 100%;
left: 0;
top: 0;
background-color: var(--not-quite-black);
    background-image: url("../public/assets/images/loading-game-new.gif");
    background-repeat: no-repeat;
    background-position: center;
  }
 
  #stopStealingMyGames {
    display: flex;
    position: fixed;
    z-index: 999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
    width: 100%;
    height: 100%;
left: 0;
/**/
top: 0;
background-color: var(--not-quite-black);
    background-image: url("../public/assets/images/dontstealmygames.png");
    background-repeat: no-repeat;
    background-position: right;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media only screen and (max-width: 700px) {
    .rando {
  display: none;    }
   }
   
  #stopStealingMyGames .stealh1 {
    color: transparent;
    font-size: 50px;
    text-align: left;
    margin-top: 50px;
    margin-left: 50px;
    font-family: 'Monument Extended';
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 100;
    -webkit-text-stroke: white 1px;
    align-items: baseline;
    display: flex;
    width: 50vw;
  }
 

#stopStealingMyGames .rando {
  color: white;
  font-size: 20px;
  text-align: left;
  margin-top: 50px;
  margin-left: 50px;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 100;
  align-items: baseline;
  display: flex;
  width: 50vw;
  font-style: italic;
}

#stopStealingMyGames button {
position: fixed;
bottom: 30px;
text-align: center;
margin-left: 50px;
width: 50vw;
background-color: var(--brown);
border: white 1px solid;
font-family: 'Monument Extended';
height: 50px;
border-radius: 15px;
transition: 0.4s;
font-size: large;
text-transform: uppercase;

}
#stopStealingMyGames button:hover {
  scale: 1.1;
  transition: 0.4s;
  color: white !important;
  }
  #stopStealingMyGames a:hover {
    color: white !important;

  }
  .game-h1 {
    text-transform: uppercase;
    letter-spacing: 4px;
    width: 100%;
  }
  .game-card-descript {
    font-size: 10px;
    margin-left: 90px;
    padding-right: 30px;
    width: 50px;
    margin-top: 10px;
    display: none;
  }
.game-card-descript-div {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
}

.back-to-top-icon-games-page {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  filter: invert(100%);
  rotate: 90deg;
}
.navtexttitle {
  font-size: 30px;
  text-transform: capitalize;
  font-style: italic;
  letter-spacing: 4px;
  color: transparent;
  -webkit-text-stroke: 1.5px white !important;
  text-transform: uppercase;
}
/*--------------------*/
  .games-container1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    /* margin-right: 50px; */
    align-items: center;
    max-width: 70%;
}


.game-card-link {
  text-decoration: none;
}

.game-card {
  position: relative;
  width: 200px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  overflow: hidden;
  transition: 0.3s;
  border: white solid 2px;
  display: flex;
  margin: 10px;
}

.game-card:hover {
  scale: .9;
  transition: 0.1s;

}

.game-card-content {
 
  width: 100%;
  background-color: rgba(0, 0, 0, 0.479);
  color: white;
  letter-spacing: 2px;
  font-weight: 100 !important;
  transition: 1.3s;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}


.game-card-content:hover {
  /*height: 50%;*/
  transition: 1.3s;
}
.loading-div-screen {
  margin:0px;
  background-size:contain;
  background-repeat: no-repeat;
  background-color: black;
  background-position-x: center;
  background-position-y: center;
}
.game-card-title {
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  font-weight: 100;
  max-width: 160px;
}

.game-card-description {
  margin: 0.5rem 0;
  font-size: 0.7rem;
  font-style: italic;
  margin: 0.35rem 1rem 0 0;
  display: none;
}

.game-card-logo {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 5px;
  left: 5px;
}
.game-card-logo-trending {
  position: absolute;
    width: 25px;
    height: 25px;
    bottom: 5px;
    right: 5px;
    background-color: white;
    border-radius: 50%;
}
.fpscounter {
     align-items: center;
    color: #fff;
    display: flex;
    font-size: 20px;
    font-style: italic;
    font-weight: 100;
    left: 60px;
    letter-spacing: 5px;
    text-transform: uppercase;
    top: 20px;
    z-index: 1000;
    background: var(--not-quite-black);
    border-radius: 10px;
    height: 72px;
    padding: 0px 20px;
    margin: 10px;

}
/* Hide the checkbox */
input[type="checkbox"] {
  display: none;
}

/* Style the label */
.toggle-label {
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: rgb(2, 2, 2);
  border-radius: 0px;
  position: relative;
  cursor: pointer;
  align-items: center;
  margin-right: 20px;
}

/* Add a small circle to indicate the state of the checkbox */
.toggle-label::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
  border-radius: 0%;
  background-color: rgb(255, 255, 255);
  transition: transform 0.2s;
}

/* Move the circle to the right to indicate the "checked" state */
input[type="checkbox"]:checked + .toggle-label::before {
  transform: translateX(30px);
}


.iframewithbar {
  width: calc(90vw);
  height: calc(90vh + 50px );
  background-color: #0e0e0e;
  margin: 0 auto;
  float: none;
  display: flex;
  border-radius: 4px;
  border: 3px solid rgb(39, 39, 39);
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column-reverse;
  
}

input::placeholder {
  color: rgba(255, 255, 255, 0.64);
  font-family: 'Monument Extended';
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 100;
  align-items: center;
  display: flex;
  font-style: italic;
  margin-left: 0px;
  margin-top: 30px;

}
.toggle-container {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 34px;
  margin: 10px;
}

.toggle-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-container label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: grey;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-container label:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-container input:checked + label {
  background-color: #00BFFF;
}

.toggle-container input:checked + label:before {
  transform: translateX(26px);
}



.game-card-content-toggled {
  bottom: 0;
  width: 200px;
  /* padding: 1rem; */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  min-height: 50px;
  transition: 1.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.game-card-toggled {
  background-size: 150px 150px;
  box-shadow: 6px 6px 0px rgba(0,0,0,0.2);
  background-color: var(--not-quite-black);
  border-radius: 4px;
  /* backdrop-filter: blur(10px); */
  background-repeat: no-repeat;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: width 0.5s, height .3s, transform;
  position: relative;
  /* width: 350px; */
  /* height: 150px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }


  .game-card-logo-hidden {
    display: none;
  }

  .togglethumb-div{
    /*background-color: var(--not-quite-black);*/
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(255, 255, 255, 0.64);

  }

  .button-game-frame {
    background-color: var(--brown);
    border: 2px solid rgb(255, 255, 255);
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 8px;
    transition: 0.2s;
    font-family: 'Monument Extended';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 100;
    font-style: italic;
    height: 5vh;
    cursor: pointer;
    z-index: 999999999;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 30px;
  }
  .button-game-frame:hover {
  /*hover effect*/
  transition: 0.2s;
  color: transparent;
  -webkit-text-stroke: white 1px;
}
  .img-frame-game {
    width: 15vw;
    height: 15vw;
    margin-top: -5vh;
    margin-bottom: 5vh;
    border-radius: 50%;
    transition: 0.2s;

  }
  .img-frame-game:hover {
  scale: 1.05;
  transition: 0.2s;
  cursor: crosshair;
  }

  .img-game-frame-back {
    background-size: cover;
    backdrop-filter: blur(20px);
    background-position: center center;
    width: calc(90vw);
    height: 90vh;    
    position: absolute;
    filter: blur(18px);

  }

  .div-game-z {
    align-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(0,0,0,.466);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 60%;
    padding: 80px 30px 30px;
    z-index: 99999;
}
.div-game-z-mobile {
  z-index: 99999;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.466);
  padding: 80px 30px 30px 30px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  max-width: 1000px;
  align-items: center;
  display: none;
}
.app-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 65vh;
}

.h1-app-content {
  text-align: center;
  font-size: 3em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 5px;

}

.img-app {
  height: 5vh;
  width: 5vh;
  border-radius: 50%;
  float: left;
}
.button-app{
  width: 30vw;
  display: flex;
  padding: 10px;
  border-radius: 15px;
  box-shadow: none;
  border: none;
  cursor: pointer;
  background-color: var(--not-quite-black) !important;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.h2-app {
  text-align: center;
  font-size: 1.5em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 5px;
  color: white;
  -webkit-text-stroke: white 1px;
  font-weight: 100;
  font-style: italic;
}

.background-blur-gamecard {
  z-index: 5;
  position: relative;
  backdrop-filter: blur(10px);
}

.filter-games-page {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;

  justify-content: center;
}

.center-text {
  text-align: center;
}


.krunkdochidden {
  display: none;
}
.krunkdocvisible {
  position: fixed;
  z-index: 99999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.container-articles {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.bfcgbigfoottext {
  width: 250px;
}
.bfcgbigfoottext-footer {
  padding: 6px;
  width: 250px;
}
.article-conent-list h3 {
  font-size: 2em;
  font-family: 'Monument Extended';
  text-transform: capitalize;
  letter-spacing: 5px;
  color: white;
  -webkit-text-stroke: white 1px;
  font-weight: 100;
  text-align: center;

}
.article-conent-list p3 {
  font-size: 1em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 5px;
  color: white;
  text-align: center;
}
.article-conent-list p {
  font-size: 1em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  /* letter-spacing: 3px; */
  color: rgb(255 255 255);
  /* -webkit-text-stroke: white 1px; */
  font-weight: 100;
  text-align: center;
  margin-bottom: 10px;
  font-style: italic;
  letter-spacing: 1px;
}


.fourofourdiv {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  height: 70vh;
}


.announcementsh1 {
  font-size: 2em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 5px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: white 1px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 10px;
}

.announcementsdiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;

}
.announcementsdiv2 {
  width: 40vw;
  height: 200px;
  background-color: var(--not-quite-black);
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.announcementsdiv2-head {
  font-size: 1.5em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 5px;
  color: white;
  -webkit-text-stroke: white 1px;
  font-weight: 100;
  text-align: center;
}
.announcementsdiv2-date {
  font-size: 1em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 3px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: white 1px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 10px;
  font-style: italic;
}
.announcementsdiv2-content {
  font-size: 1em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 2px;
  color: rgb(255, 255, 255);
  font-weight: 100;
  text-align: center;
  margin-bottom: 10px;
  font-style: italic;
}

.new-announcements {
  background-color: blue;
  border-radius: 3px;
  width: 30px;
  height: 20px;
  margin-bottom: 10px;
}


.h1-head-404 {
  font-size: 3em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 5px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: white 1px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 10px;
}
.button-404 {
  background-color: rgb(33, 33, 33);
  border-radius: 3px;
 padding: 10px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  width: 40%;
  border-radius: 10px;
  float: left;
  margin-left: 37px;

}
.floatright {
 float: right !important;
}

* {
  color-scheme: dark;
}

  .img-game-icon {
    width: 75%;
    height: 75%;
    border-radius: 10%;
  }


  .grid-game-topstuff {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-end;
    /* max-width: 50vw; */
    background-color: #0e0e0e;
    padding: 10px;
    margin-left: 30px;
    border-radius: 10px;
  }
  .img-in-game {
width: 15vw;
    height: 15vw;
    margin-top: -5vh;
    margin-bottom: 5vh;
    transition: 0.2s;  
    border-radius: 10%;
}
.search-game-page-bar{
  align-items: center;
    color: #fff;
    display: flex;
    font-size: 20px;
    font-style: italic;
    font-weight: 100;
    left: 60px;
    letter-spacing: 5px;
    text-transform: uppercase;
    top: 20px;
    z-index: 1000;
    background: var(--not-quite-black);
    padding: 15px;
    border-radius: 10px;
    max-height: 72px;
    margin: 10px;

}

.form-games-search {
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;

}
.game-h1-bgs {
  text-transform: uppercase;
  letter-spacing: 4px;
  width: 100%;
}

.tabcloakbutton-big {
  align-items: center;
 color: #fff;
 display: flex;
 font-size: 20px;
 font-style: italic;
 font-weight: 100;
 left: 60px;
 letter-spacing: 5px;
 text-transform: uppercase;
 top: 20px;
 z-index: 1000;
 background: var(--not-quite-black);
 border-radius: 10px;
 height: 72px;
 padding: 0px 20px;
 margin: 10px;

}

.game-info-top {
  border-radius: 10px;
  display: flex;
  max-width: calc((90vw - 40px));
  padding: 20px;
  height: auto;
  margin-bottom: 0px;
  margin-left: 30px;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  justify-content: space-between;
}


.random-button-game-page {
  border: none;
  background: none;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: white;
  font-size: 20px;
  font-style: italic;
  font-weight: 100;
  font-family: 'Monument Extended', sans-serif;
  cursor: pointer;
}


     .landing-section2-last {
      height: auto;
      width: 100vw;
      margin-bottom: -200px;
      background-image: linear-gradient(to bottom, rgb(0 0 0), #0e0e0e91);
     } 
.landing-section2 {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgb(0, 0, 0));
}
.landing-section2-small{
  height: auto;
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgb(0, 0, 0));
}
.landing-section3 {
  
  background-image: linear-gradient(0deg,#000,rgba(255,0,0,0),#000);  
  width: 100vw;
  display: grid;
    justify-content: center;
}

html {
  scroll-behavior: smooth
}
.arrow-main-page {
  width: 30px;
  height: 30px;
  rotate: -90deg;
  filter: invert(100%);
  transition: 0.3s;
  
}
.arrow-main-page:hover {
  scale: 1.1;
  transition: 0.3s;
}

.text-container-main-page {
  /*center the text on top of the video*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

}

.bgs-title-main-page-p {
  font-style: italic;
  font-size: 1.5em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 3px;

}
.bgs-title-main-page {
  font-size: 4em;
  font-family: 'Monument Extended';
  text-transform: uppercase;
  letter-spacing: 5px;
  -webkit-text-stroke: white 3px;
  color: transparent;
}
video { 
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(6px);
  z-index: -100;

}
@media only screen  
   and (max-width : 600px) {
    .bgs-title-main-page  {
      font-size: 2.6rem;
      -webkit-text-stroke: white 1px;
      letter-spacing: 3px
    }
    .bgs-title-main-page-p {
      font-size: 1em;
    }
    }


.logo2 {
  margin-bottom: 20px;
}

.logo2 img {
  max-width: 200px;
}

.headline2 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.subheadline2 {
  font-size: 16px;
  color: #b0b0b0;
  margin-bottom: 70px;
  font-style: italic;
}

.cta-button2 {
  background-color: var(--brown);
  color: #ffffff;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:  0.3s ease;
  text-transform: uppercase;

}
.pagination {
  MARGIN-TOP: 45PX;
}
.cta-button2:hover {
  scale: 1.2 1.0;
  color: #ffffff;
}

.slope-section {
  margin-top: 50px;
    background-size: cover;
    background-position: center;
    width: 50vw;
    background-image: url("../public/assets/images/slopead.png");
    padding: 30px;
    border-radius: 30px;
    margin-left: 20px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center


}
.blockpost-section {
  margin-top: 50px;
    background-size: cover;
    background-position: center;
    width: 50vw;
    background-image: url("../public/assets/images/blockpostpromo-mobile.jpg");
    padding: 30px;
    border-radius: 30px;
    margin-left: 20px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center
}
.blockpostimg {
width: 100%;

}

/* GameSlider.css */

.game-slider-container {
  padding: 50px;
  max-width: 85vw;
  margin: 0 auto;

}

.game-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.game-slide img {
  width: 100%;
  max-height: 250px; /* Adjust the maximum height as needed */
  min-height: 250px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: 0.3s;
}
.game-slide:hover  img {
  transition: 0.3s;
  scale: 0.97;
}

.game-title {
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;

}






















.blockpost-ad {
  width: calc(400px);
  background-color: black;
border-bottom: white solid 2px;
}
.top-games-title {
  font-size: 4rem;
  text-align: center;
  margin-left: -15px;
  margin-top: 0;

}
.top-games-title-cb {
  font-size: 4rem;
  text-align: center;
  color: transparent;
  -webkit-text-stroke: white 1px;
  text-transform: uppercase;
  letter-spacing: 3px;
margin-top: 200px;

}
.top-games-des-cb {
  font-size: 2rem;
  text-align: left;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;

}
.button-playnow {
  background-color: transparent;
  color: white;
  padding:15px;
  text-decoration: none;
  font-size: 2vw !important;
  border-radius: 8px;
  transition: 0.2s;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-style: italic;
  height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  /*center on page*/
  margin: auto auto;
  border: white solid 2px;
  width: 30vw;
}
.button-playnow:hover {
transition: cubic-bezier(0.075, 0.82, 0.165, 4s);
background-color: transparent;
color: transparent;
-webkit-text-stroke: 2px white;
scale: 0.9;
}
.landing-section2-cb {
  display: flex;
    flex-direction: column;
    margin: auto;
    height: auto;
}
.blockpost-button {
  background-color: rgb(0, 0, 0);
    width: 200px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 10px;
    letter-spacing: 2px;
    cursor: pointer;
}

.blockpost-button-icon {
  width: 30px;
  height: 30px;
  filter: invert(100%);
  transition: 0.3s;
  padding-left: 10px;
  display: none; /* Hide the icon by default */
}
.logo2-slope {
  height: 60px;
  width: 60px;
  z-index: 10;
  position: relative;
  float: right;
  display: none;
}
.slope-section-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  margin-bottom: 100px;

}
.slope-section-blur {
  width: calc(50vw + 60px);
  margin-left: 10px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  position: absolute;
  z-index: 9;
  border-radius: 30px;
  backdrop-filter: blur(3px);
  border: white solid 2px;
  height: 260px;

}

.slope-subheadline {
  z-index: 10;
  position: relative;
  color: #d7d7d7;
  font-style: italic;
  padding-bottom: 20px;
}
.slope-headline {
  z-index: 10;
    position: relative;
}
.slope-cta-button {
  z-index: 10;
  position: relative;
  background-color: rgb(255 255 255);
  border-radius: 10px;
  padding: 13px;
  color: black !important;
  transition: 0.3s;   

}
.slope-cta-button:hover {
background-color: black;
color: white !important;
transition: 0.3s;   
}


@media only screen 
   and (max-width : 825px) {
    .slope-subheadline {
      display: none;
    }
    }





@media only screen 
   and (max-width : 468px) {
    .footer-links {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      align-content: flex-start;
    }
    .announcements-btn {
      display: none;
    }
    .footer-logo{
      display: none;
    }
    .slope-section-div {
      display: none;
    }
    .card1s {
      display: none !important;
    }
    .hero-p1 {
      display: none;
    }
    .landing-section2{
      padding: 10px;
    }
    .game-card-toggled .game-card-content-toggled{ 
      width: 350px;
    }
}



.logo-gamespage-des {
  height: 25%;
  width: 25%;
  
}
.game-content-mobile {
  display: none;
}

@media only screen 
   and (max-width : 900px) {
    .game-content-mobile {
      display: flex;
    }
    .game-content {
      display: none;
    }
    .div-game-z-mobile {
      display: flex;
      margin-top: 20px;
    }
    .iframewithbar {
      display: none;
    }
    .button-game-frame {
      font-size: 0.7rem;
    }
    .navtexttitle {
      display: none;
    }
   }

   .grid-game-topstuff{
    display: none;
   }


   .center-text {
    text-align: center;
   }
   #loading-div-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999999999;
    right: 0;
    background-color: #000;
    top: 0;
    background-position: top;
    background-size: 90%;
    background-repeat: no-repeat;
    /* background-image: url(../assets/images/loading-game-new.gif); */
    display: flex;
    letter-spacing: 3px;
    font-weight: 100;
    -webkit-text-stroke: 1px white;
    color: transparent;
    text-transform: uppercase;
    font-style: italic;
    font-size: 2rem;
    flex-direction: column;
    align-items: center;
  }
  .banner-ad-adsterra-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  .loading-div-button {
    width: auto;
    display: flex;
    height: 50px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: none;
    border: 3px white solid;
    cursor: pointer;
    background-color:transparent !important;
    margin: 20px;
    /* align-content: center; */
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    opacity: 0;
    animation: appear 0.5s ease-in-out 2.5s forwards; /* 2s delay before the animation starts */

  }
  .loading-div-button:hover {
    background-color: transparent !important;
    color: transparent;
    -webkit-text-stroke: 1px rgb(255, 255, 255);
    transition: 0.3s;
    scale: 0.9;
  }
  .loading-text {
    opacity: 1;
    animation: hide 0.5s ease-in-out 2s forwards; /* 2s delay before the animation starts */
    margin: 0px;
  }
 
.loading-gif {
  height: 70%;
  width: auto;
}
  /* Keyframes for the animation */
  @keyframes appear {
    to {
      opacity: 1;
    }
  }
    /* Keyframes for the animation */
    @keyframes hide {
      to {
        opacity: 0;
        display: none;
      }
    }

    .minecraft-text-main-page {
      font-family: 'Minecraft';
      color: yellow;
      rotate: -10deg;
      position: absolute;
      text-shadow: #424109 4px 4px 0px;
      font-size: 1rem;
      letter-spacing: 1.5px;
      animation: zoominout 2s infinite alternate ease-in-out 0s forwards; /* 2s delay before the animation starts */
      z-index: 9999999;
      text-align: center;
      right: 100px;
      bottom: 60px;
      cursor: pointer;
    }

    @keyframes zoominout {
      to {
scale: 1.2;      
}
    }

    .active-pagenation {
      background-color: #ffffff;
      color: rgb(0, 0, 0);
      border: white solid 2px;
      padding: 10px;
      border-radius: 10px;
      margin: 10px;
      cursor: pointer;
    }
    .pagenation {
      transition: .5s;
    }
    .pagenation-notactive {
      background-color: transparent;
      color: white;
      border: white solid 2px;
      padding: 10px;
      border-radius: 10px;
      margin: 10px;
      cursor: pointer;
    }


    /* /////////////////////////// */
    .announcements {
      position: fixed;
      bottom: 20px;
      left: 20px;
      z-index: 1000;
    }
    
    .announcements-btn {
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    .announcements-btn:hover {
      background-color: #555;
      border: white solid 2px;
    }
    
    .announcements-menu {
      position: absolute;
      bottom: 50px;
      left: 0;
      background-color: #3339;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      color: #fff;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      max-height: 50vh;
      display: none;
      transform: translateY(10px);
      transition: opacity 0.3s ease, transform 0.3s ease;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      justify-content: center;
      width: auto;
      overflow:auto;
      overflow-x: hidden;
    }
    
    .announcements-menu.active {
      opacity: 1;
      transform: translateY(0);
      display: flex;
      transition: 1s;


    }
    
    .announcements-menu h2 {
      margin:10px;
      margin-left: 0;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 35px;
      
    }
    
    .announcement-list {
      margin: 0;
    }
    
    .announcement-item {
      margin-bottom: 20px;
      border: white solid 2px;
      padding: 10px;
      border-radius: 10px;

    }
    
    .announcement-title {
      font-size: 25px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      text-align: center;
    }
    
    .announcement-description {
      font-size: 16px;
      margin-top: 5px;
      font-style: italic;
      font-weight: 400;
      margin-left: 10px;

    }
    
    .announcement-date {
      font-size: 10px;
      color: #ccc;
      font-weight: 100;
      margin-left: 10px;
      margin-top: 15px;
    }
    
    .close-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      border-radius: 25px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .5s;
      border: 2px transparent solid;
      background-color: transparent;

    }
    
    .close-btn:hover {
      color: #ccc;
      border: white solid 2px;
      transition: .5s;
    }
    